<script>
/* eslint-disable */
import {apiGetCateList} from "@/common/api";

export default {
  name: "mainHeader",
  props: {
    selected: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // hover标签索引，1-7，控制加减号显示，0代表没有hover
      tagIndex: 0,
      /** 窄屏幕系列 */
      // 窄屏幕-小图标点击
      mClick: false,
      // 窄屏幕-一级菜单当前选中

      // 是否登录
      isLogin: false,
      avatarURL: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.jpg",

      // 图片-全分类
      tabIconAll: null,
      // 分类列表
      cateList: [],

      // 用于存储用户选择跳过弹窗的日期
      popupSkipDate: null,
      isPopShow: false,
      isNotShowClick: false,
    }
  },
  computed: {
    shouldShowPopup() {
      // 检查localStorage中是否有跳过的日期
      const skipDate = localStorage.getItem('popupSkipDate');
      if (skipDate) {
        // // 如果有跳过的日期，检查是否是今天
        // const today = new Date().toISOString().split('T')[0]; // 只取日期部分
        // if (skipDate === today) {
        //   // 如果是今天，则不显示弹窗
        //   return false;
        // }
        return false;
      }
      // 否则显示弹窗
      return true;
    },
  },
  created() {
    this.getImg();
    // 获取登录信息
    this.getLoginInfo();
    // 获取分类列表
    this.getCateList();
  },
  mounted() {
    // 在组件挂载时检查是否需要显示弹窗
    if (this.shouldShowPopup) {
      // 显示弹窗的代码（例如，通过改变一个控制v-if或v-show的data属性）
      this.isPopShow = true;
    }
  },
  destroyed() {

  },
  methods: {
    getImg() {
      this.tabIconAll = this.$img.MALL.cate_all;
    },
    /**
     * 跳转到指定路径
     * @param url
     */
    toPath(url){
      this.tagIndex = 0
      this.$router.push({
        path: url,
      })
    },
    toSkinList(item, index){
      this.tagIndex = 0
      let bIsSkin = this.$route.path === '/skin'
      this.$router.push({
        path: '/skin',
        query: {
          s: index
        }
      })
      if(bIsSkin) {
        this.$router.go(0)
      }
    },
    /**
     * 鼠标hover事件
     * @param index
     */
    onTagHover(index) {
      this.tagIndex = index
    },
    onTagLeave() {
      this.tagIndex = 0
    },
    /** 鼠标点击事件监听（检测是否关闭抽屉） */
    onMCLick() {
      if(this.mClick === false) {
        this.mClick = true
        setTimeout(() => {
          document.addEventListener('click', this.handleClickOutside)
        }, 100)
      }
    },
    handleClickOutside(event) {
      // 检测点击的目标是否在指定的 div 之外
      if (this.$refs.refDrop && !this.$refs.refDrop.contains(event.target) && this.mClick === true) {
        this.mClick = false
        document.removeEventListener('click', this.handleClickOutside)
      }
    },
    /** 登陆用方法 */
    gotoLogin() {
      window.location.href = "/console";
    },
    /** 获取登录信息 */
    getLoginInfo() {
      let cookieId = this.$cookie.get("amrId");
      if(cookieId !== undefined && cookieId !== null && cookieId !== '') {
        this.isLogin = true;
        this.avatarURL = window.location.origin + this.$cookie.get("amrAvatar");
      } else {
        this.isLogin = false;
      }
    },
    /** 获取分类列表 */
    async getCateList() {
      this.cateList = [];
      this.cateList.push({
        name: "全部",
        pic: this.tabIconAll
      });
      const res = await apiGetCateList();
      if(res.data.code === 200) {
        this.cateList = this.cateList.concat(res.data.data);
        // 拼图
        for(let i = 1; i < this.cateList.length; i++) {
          this.cateList[i].pic = this.$config.PICTURE_URL + this.cateList[i].pic;
        }
      }
    },
    /** 关闭弹窗 */
    closePopupAndSkipToday() {
      if(this.isNotShowClick === true) {
        // 关闭弹窗并设置跳过的日期为今天
        this.popupSkipDate = new Date().toISOString().split('T')[0];
        localStorage.setItem('popupSkipDate', this.popupSkipDate);
      }
      // 这里可以添加代码来关闭弹窗，例如使用Vue的v-show或v-if结合一个data属性来控制弹窗的显示与隐藏
      this.isPopShow = false;
    },
    /** 跳转绝对路径 */
    gotoAbsoluteURL(url) {
      window.open(url);
    },
    /** 跳转-改进 */
    gotoImprove() {
      this.$router.push({
        path: "/platforms/improve"
      })
    }
  },
}
</script>

<template>
  <div class="root-container">
    <div class="box">
      <div class="logo">
        <img class="logo-img" :src="require('@/assets/images/pc-logo-hd.png')"
             alt @click="toPath('/')">
        <el-divider direction="vertical" class="mini-title-line"></el-divider>
        <div class="mini-box">
          <el-tag class="mini-title" effect="plain" @click="gotoImprove">请求产品改进(RFI)</el-tag>
        </div>
      </div>

      <!--
      序号顺序：（乱序原因：重构次数较多）
      测肤-3
      诊断-4
      护肤-5
      祛痘-2
      清颜系列-1
      服务平台-6
      立即购买-7
      搜索-8
      -->
      <div class="nav-items">
        <div class="nav-item" >
          <div class="nav-button" @mouseenter="onTagHover(3)" @click="toPath('/ai')"
               :class="{'nav-button-selected': selected === 3}">
            测肤
            <div class="nav-plus-icon">
              <i :class="{'el-icon-minus':tagIndex === 3, 'el-icon-plus': tagIndex !== 3}"></i>
            </div>
          </div>
        </div>
        <div class="nav-item">
          <div class="nav-button" @mouseenter="onTagHover(4)"
               @click="toPath('/diagnosis/remote')" :class="{'nav-button-selected': selected === 4}">
            诊断
            <div class="nav-plus-icon">
              <i :class="{'el-icon-minus':tagIndex === 4, 'el-icon-plus': tagIndex !== 4}"></i>
            </div>
          </div>
        </div>
        <div class="nav-item" >
          <div class="nav-button" @mouseenter="onTagHover(5)"
               @click="toPath('/care')" :class="{'nav-button-selected': selected === 5}">
            护肤
            <div class="nav-plus-icon">
              <i :class="{'el-icon-minus':tagIndex === 5, 'el-icon-plus': tagIndex !== 5}"></i>
            </div>
          </div>
        </div>
        <div class="nav-item">
          <div class="nav-button" @mouseenter="onTagHover(2)"
               @click="toPath('/acne/intro')" :class="{'nav-button-selected': selected === 2}">
            肌肤修护
            <div class="nav-plus-icon">
              <i :class="{'el-icon-minus':tagIndex === 2, 'el-icon-plus': tagIndex !== 2}"></i>
            </div>
          </div>
        </div>
        <div class="nav-item" >
          <div class="nav-button" @mouseenter="onTagHover(1)"
               @click="toSkinList(0,0)" :class="{'nav-button-selected': selected === 1}">
            护肤定制
            <div class="nav-plus-icon">
              <i :class="{'el-icon-minus':tagIndex === 1, 'el-icon-plus': tagIndex !== 1}"></i>
            </div>
          </div>
        </div>
        <div class="nav-item">
          <div class="nav-button" @mouseenter="onTagHover(6)"
               @click="toPath('/about')" :class="{'nav-button-selected': selected === 6}">
            服务平台
            <div class="nav-plus-icon">
              <i :class="{'el-icon-minus':tagIndex === 6, 'el-icon-plus': tagIndex !== 6}"></i>
            </div>
          </div>
        </div>
        <div class="nav-item">
          <div class="nav-button" @mouseenter="onTagHover(7)">
            立即购买
            <div class="nav-plus-icon">
              <i :class="{'el-icon-minus':tagIndex === 7, 'el-icon-plus': tagIndex !== 7}"></i>
            </div>
          </div>
        </div>
<!--        <div class="nav-item">-->
<!--          <div class="nav-button-search" @click="toPath('/search')" :class="{'nav-button-selected': selected === 8}">-->
<!--            <div class="nav-search-icon">-->
<!--              <i class="el-icon-search"></i>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <el-divider direction="vertical"></el-divider>
        <div class="nav-item">
          <div class="nav-button-login" @click="gotoLogin">
            <div class="nav-non-login" v-if="isLogin === false">
              <div class="nav-button nav-button-border">
                登录
              </div>
            </div>
            <div class="nav-is-login" v-else>
              <div class="nav-avatar-box">
                <img class="nav-avatar-img" :src="avatarURL" alt>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 窄屏幕的菜单按钮 -->
      <div class="nav-m-items">
        <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg" @click="onMCLick">
          <image :href="require('/src/assets/images/m-list.svg')" width="40" height="40" />
        </svg>
      </div>
    </div>

    <!--
      序号顺序：（乱序原因：重构次数较多）
      测肤-3
      诊断-4
      护肤-5
      祛痘-2
      清颜系列-1
      服务平台-6
      立即购买-7
      搜索-8
      -->
    <!-- 抽屉 -->
    <div class="drop" v-show="tagIndex !== 0" @mouseleave="onTagLeave">
      <div class="drop-container">
        <div class="drop-about" v-show="tagIndex === 3">
          <div class="about-box" @click="toPath('/ai')">
            <img class="ai-img" alt :src="require('@/assets/images/diagnosis/bg-1-head.png')">
            <div>皮肤AI分析</div>
          </div>
          <div class="about-box" @click="toPath('/doctor')">
            <img class="bowman-img" alt :src="require('@/assets/images/header/tested-bowman.jpg')">
            <div>鲍曼医生测试</div>
          </div>
        </div>
        <div class="drop-about" v-show="tagIndex === 4">
          <div class="about-box" @click="toPath('/diagnosis/remote')">
            <img class="about-img" alt :src="require('@/assets/images/header/about-heart.jpg')">
            <div>远程诊断</div>
          </div>
          <div class="about-box" @click="toPath('/diagnosis/index')">
            <img class="about-img" alt :src="require('@/assets/images/header/about-us.jpg')">
            <div>医肤档案</div>
          </div>
        </div>
        <div class="drop-health" v-show="tagIndex === 5">
          <div class="about-box" @click="toPath('/care')">
            <img class="health-img" alt :src="require('@/assets/images/header/care-1.jpg')">
            <div>科学护肤模型</div>
          </div>
        </div>
        <div class="drop-health" v-show="tagIndex === 2">
<!--          <div class="about-box" @click="toPath('/acne')">-->
<!--            <img class="health-img" alt :src="require('@/assets/images/header/acne-1.jpg')">-->
<!--            <div>祛痘系列</div>-->
<!--          </div>-->
          <div class="about-box" @click="toPath('/acne/intro')">
            <img class="health-img" alt :src="require('@/assets/images/header/acne-2.jpg')">
            <div>系列介绍</div>
          </div>
        </div>
        <div class="drop-skin" v-show="tagIndex === 1">
          <div class="skin-box">
<!--            <div class="sk-series">-->
<!--              <div class="sk-se-title">-->
<!--                按系列分-->
<!--                <div class="sk-line"></div>-->
<!--              </div>-->
<!--              <div class="sk-se-box">-->
<!--                <div class="sk-se-row">-->
<!--                  <div class="about-box" @click="toSkinList(1,0)">-->
<!--                    <img class="health-img" alt :src="require('@/assets/images/header/se-1.jpg')">-->
<!--                    <div>肌研极润</div>-->
<!--                  </div>-->
<!--                  <div class="about-box" @click="toSkinList(2,0)">-->
<!--                    <img class="health-img" alt :src="require('@/assets/images/header/se-2.jpg')">-->
<!--                    <div>面膜系列</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="sk-se-row sk-se-row-margin" @click="toSkinList(3,0)">-->
<!--                  <div class="about-box">-->
<!--                    <img class="health-img" alt :src="require('@/assets/images/header/se-3.jpg')">-->
<!--                    <div>新碧防晒</div>-->
<!--                  </div>-->
<!--                  <div class="about-box" @click="toSkinList(4,0)">-->
<!--                    <img class="health-img" alt :src="require('@/assets/images/header/se-4.jpg')">-->
<!--                    <div>手霜系列</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <div class="sk-demand">
              <div class="sk-se-title">
                按功效分
                <div class="sk-line"></div>
              </div>
              <div class="sk-de-box">
                <div class="sk-de-row">
                  <div class="sk-de-item" v-for="(item, index) in cateList" :key="index" @click="toSkinList(item, index)">
                    <div class="sk-de-img-box">
                      <img class="sk-de-img" alt :src="item.pic">
                    </div>
                    <div>{{ item.name }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="drop-about" v-show="tagIndex === 6">
          <div class="about-box-logo" @click="toPath('/about')">
            <img class="about-img-logo" alt :src="require('@/assets/images/footer_logo_hd.png')">
            <div>品牌故事</div>
          </div>
          <div class="about-box-logo" @click="toPath('/platforms/open')">
            <img class="about-img-logo" alt :src="require('@/assets/images/header/platforms-open.jpg')">
            <div>开放平台</div>
          </div>
          <div class="about-box-logo" @click="toPath('/diagnosis/remote')">
            <img class="about-img-logo" alt :src="require('@/assets/images/header/platforms-consultation.jpg')">
            <div>医肤问诊</div>
          </div>
          <div class="about-box-logo" @click="toPath('/platforms/improve')">
            <img class="about-img-logo" alt :src="require('@/assets/images/header/diagnosis-archives.jpg')">
            <div>产品共建</div>
          </div>
        </div>
        <div class="drop-mall" v-show="tagIndex === 7">
          <div class="mall-enter-box">
            <div class="mall-row">
              <div class="mall-item" @click="gotoAbsoluteURL('https://skycos.taobao.com')">
                <div class="mall-item-icon-box">
                  <i class="iconfont icon-taobao mall-item-icon"></i>
                </div>
                <div>淘宝企业店</div>
              </div>
              <div class="mall-item">
                <div class="mall-item-icon-box">
                  <i class="iconfont icon-tianmao mall-item-icon"></i>
                </div>
                <div>天猫旗舰店</div>
              </div>
            </div>
            <div class="mall-row mall-row-margin">
              <div class="mall-item">
                <div class="mall-item-icon-box">
                  <i class="iconfont icon-pay-jingdong mall-item-icon"></i>
                </div>
                <div>京东官方旗舰店</div>
              </div>
              <div class="mall-item">
                <div class="mall-item-icon-box">
                  <i class="iconfont icon-pinduoduo mall-item-icon"></i>
                </div>
                <div>拼多多官方旗舰店</div>
              </div>
            </div>
            <div class="mall-row mall-row-margin">
              <div class="mall-item">
                <div class="mall-item-icon-box">
                  <i class="iconfont icon-xiaohongshu mall-item-icon"></i>
                </div>
                <div>小红书专业店</div>
              </div>
              <div class="mall-item">
                <div class="mall-item-icon-box">
                  <i class="iconfont icon-douyin1 mall-item-icon"></i>
                </div>
                <div>抖音专卖店</div>
              </div>
            </div>
          </div>
          <div class="mall-code-box">
            <div class="mall-code-item">
              <img class="mall-code-img" alt :src="require('@/assets/images/code-MALL.jpg')">
              <div class="mall-code-text">小茉护肤定制</div>
            </div>
            <div class="mall-code-item">
              <img class="mall-code-img" alt :src="require('@/assets/images/code-test.jpg')">
              <div class="mall-code-text">在线测评平台</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- code -->
    <div class="code-box-list">
      <div class="code-box c-b-1">
        <i class="iconfont icon-gongzhonghao c-consult c-c-1"></i>
        <div class="c-code-container">
          <div class="c-code-text">微信扫码公众号</div>
          <img class="c-code-img" alt :src="require('@/assets/images/code-public.png')">
        </div>
      </div>
      <div class="code-box c-b-2">
        <i class="iconfont icon-xiaochengxu c-consult c-c-2"></i>
        <div class="c-code-container">
          <div class="c-code-text">微信扫码进入护肤</div>
          <img class="c-code-img" alt :src="require('@/assets/images/code-mini.png')">
        </div>
      </div>
      <div class="code-box c-b-3">
        <i class="iconfont icon-kefu c-consult"></i>
        <div class="c-code-container">
          <div class="c-code-text">微信扫码联系客服</div>
          <img class="c-code-img" alt :src="require('@/assets/images/code-consult.png')">
        </div>
      </div>
    </div>

    <!-- 小抽屉 -->
    <div class="drop-m" v-show="mClick" ref="refDrop">
      <div class="drop-m-item">
        功能开发中，请拉宽屏幕以正常显示
      </div>
    </div>

    <!-- 弹窗 -->
    <el-dialog :visible.sync="isPopShow" width="80%" :show-close="false" :modal-append-to-body="false" :close-on-click-modal="false">
      <div class="pop-container">
        <div class="pop-main-box">
          <div class="pop-second-box">
            <div class="pop-bg-box">
              <div class="pop-upper-box">
                <div class="pop-upper-title">
                  了解肤质匹配，配方定制
                </div>
                <div class="pop-upper-subtitle">
                  联系医肤师获取专家服务
                </div>
              </div>
              <div class="pop-down-box">
                <div class="pop-down-first">
                  <div class="pop-down-line-1"></div>
                  <div class="pop-down-title">
                    任何关于肌肤/痘痘的疑问
                  </div>
                  <div class="pop-down-line-2"></div>
                </div>
                <div class="pop-down-second">
                  <div class="pop-down-code-box">
                    <img class="pop-down-code-img" src="@/assets/images/code-public.png" alt>
                    <div class="pop-down-code-text">关注服务号</div>
                  </div>
                  <div class="pop-down-code-box">
                    <img class="pop-down-code-img" src="@/assets/images/code-consult.png" alt>
                    <div class="pop-down-code-text">咨询客服</div>
                  </div>
                </div>
                <div class="pop-down-third">我们将尽快为您提供专业的解答和服务！</div>
                <div class="pop-down-forth">
                  <el-checkbox v-model="isNotShowClick">不再弹出</el-checkbox>
                </div>
              </div>
              <div class="pop-second-close-box" @click="closePopupAndSkipToday">
                <i class="el-icon-close pop-second-close-icon"></i>
              </div>
              <img class="pop-star-1" src="@/assets/images/index/index-pop-1.png" alt>
              <img class="pop-star-2" src="@/assets/images/index/index-pop-2.png" alt>
              <img class="pop-star-3" src="@/assets/images/index/index-pop-3.png" alt>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<style lang="scss" scoped>
@import "~@/styles/mainHeader.scss";
::v-deep .el-dialog__header{
  padding: 0;
}
::v-deep .el-dialog{
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  box-shadow: 0 0 0 0;
}
.pop-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .pop-main-box {
    width: 877px;
    height: 731px;
    background-image: url("@/assets/images/index/index-pop-4.png");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;

    .pop-second-box {
      margin-top: 40px;
      width: 671px;
      height: 597px;
      background-image: url("@/assets/images/index/index-pop-5.png");
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;

      .pop-bg-box {
        background-image: url("@/assets/images/index/index-pop-bg.png");
        width: 600px;
        height: 500px;
        background-size: cover;
        background-repeat: no-repeat;

        .pop-upper-box {
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
          color: #FFFFFF;
          font-weight: bold;
          padding: 26px;
          margin: 0 auto;
          width: fit-content;

          .pop-upper-title {
            font-size: 20px;
          }
          .pop-upper-subtitle {
            margin-top: 10px;
            font-size: 36px;
            letter-spacing: 2px;
          }
        }
        .pop-down-box {
          border-radius: 30px;
          padding: 30px 80px;

          .pop-down-first {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;

            .pop-down-line-1 {
              height: 2px;
              width: 50px;
              background: linear-gradient(to right, #fff, #777572);
            }
            .pop-down-title {
              font-size: 20px;
              font-weight: bold;
              color: #000000;
            }
            .pop-down-line-2 {
              height: 2px;
              width: 50px;
              background: linear-gradient(to left, #fff, #777572);
            }
          }
          .pop-down-second {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 60px;
            margin-top: 30px;

            .pop-down-code-box {
              text-align: center;

              .pop-down-code-img {
                width: 140px;
                height: 140px;
              }
              .pop-down-code-text {
                margin-top: 10px;
                font-size: 16px;
                font-weight: bold;
                color: #000;
              }
            }
          }
          .pop-down-third {
            text-align: center;
            margin-top: 20px;
            font-size: 16px;
            color: #aeaeae;
          }
          .pop-down-forth {
            margin-top: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        .pop-second-close-box {
          position: absolute;
          top: 0;
          right: 10px;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          border: 2px solid #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .pop-second-close-icon {
            color: #FFFFFF;
            font-size: 30px;
          }
        }
        .pop-star-1 {
          width: 84px;
          height: 86px;
          position: absolute;
          top: 400px;
          left: 10px;
        }
        .pop-star-2 {
          width: 54px;
          height: 55px;
          position: absolute;
          top: 30px;
          left: 400px;
        }
        .pop-star-3 {
          width: 64px;
          height: 67px;
          position: absolute;
          top: 200px;
          right: 20px;
        }
      }
    }
  }
}
</style>
